import React from 'react'
import Footer from '../footer'
// import Footer from "./footer"
import Navbar from '../navbar'
import './layout.css'

const BaseLayout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-1 mt-16">{children}</main>
      <Footer />
    </div>
  )
}

export default BaseLayout
