import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';

const Footer = () => {
    return(
        <footer className="bg-primary w-full py-8">
            <div className="max-w-screen-xl mx-auto px-4">
                <ul className="grid grid-cols-1 md:grid-cols-4 text-center text-lg font-light">
                    <li className="my-2">
                        <AnchorLink to="/#" title="App" className="text-gray-300 hover:text-white transition-colors duration-200" />
                    </li>
                    <li className="my-2">
                        <AnchorLink to="/imprint" title="Impressum" className="text-gray-300 hover:text-white transition-colors duration-200" />
                    </li>
                    <li className="my-2">
                        <AnchorLink to="/privacy" title="Datenschutz" className="text-gray-300 hover:text-white transition-colors duration-200" />
                    </li>
                    <li className="my-2">
                        <AnchorLink to="/#contact" title="Kontakt" className="text-gray-300 hover:text-white transition-colors duration-200" />
                    </li>
                </ul>
                <div className="pt-8 flex max-w-min mx-auto items-center justify-between">
                    <a href="#" className="text-gray-300 hover:text-white transition-colors duration-200 mr-2 ml-2">
                        <svg width="20" height="20" fill="currentColor" className="text-xl hover:text-white transition-colors duration-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                            </path>
                        </svg>
                    </a>
                    <a href="#" className="text-gray-300 hover:text-white transition-colors duration-200 mr-2 ml-2">
                        <svg width="20" height="20" fill="currentColor" className="text-xl hover:text-white transition-colors duration-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z">
                            </path>
                        </svg>
                    </a>
                </div>
                <div className="text-center text-white pt-10 sm:pt-12 font-light flex items-center justify-center">
                    &#169; ViSpecs GmbH
                </div>
            </div>
        </footer>
    );
}

export default Footer;