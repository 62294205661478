import React, {useState} from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import LogoText from "../images/logo_text.png";
import { Link } from "gatsby";

const Navbar = () => {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const handleMenuButtonClick = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    }

    return(
        <div>
            <nav className="bg-white dark:bg-gray-800 fixed top-0 inset-x-0 z-50">
                <div className="mx-auto px-5 md:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className=" flex items-center">
                            <Link to="/">
                                <img className="w-36" src={LogoText} alt="Workflow"/>
                            </Link>
                            <div className="hidden md:block">
                                <div className="ml-10 flex items-baseline space-x-4">
                                    <AnchorLink to="/#" title="App" className="text-gray-700 hover:text-gray-500 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200" />
                                    <AnchorLink to="/#team" title="Über uns" className="text-gray-700 hover:text-gray-500 dark:text-white  dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200" />
                                    <AnchorLink to="/#contact" title="Kontakt" className="text-gray-700 hover:text-gray-500 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200" />
                                </div>
                            </div>
                        </div>
                        <div className="block">
                            <div className="ml-4 flex items-center md:ml-6">
                                <div className="ml-3 relative">
                                    <div className="relative inline-block text-left">
                                        <div>
                                            <button type="button" className="flex items-center justify-center w-full rounded px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none transition duration-200" id="options-menu">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="-mr-2 flex md:hidden">
                            <button onClick={() => handleMenuButtonClick()} className="text-gray-700 dark:text-white hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="md:hidden" hidden={!mobileMenuOpen}>
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <AnchorLink to="/#" title="App" className="text-gray-700 hover:text-gray-500 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium" />
                        <AnchorLink to="/#team" title="Über uns" className="text-gray-700 hover:text-gray-500 dark:text-white block px-3 py-2 rounded-md text-base font-medium" />
                        <AnchorLink to="/#contact" title="Kontakt" className="text-gray-700 hover:text-gray-500 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium" />
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar